export const hr_data = [
    {
      section_title: "Results from HR Survey",
      charts: [
        {
            "id": 0,
            "labels" : ["Full-Time employee","Part-time","Temporary","Seasonal employee","Paid intern","Unpaid intern","Apprentice / Fellow"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [89.17, 28.83, 9.94,  3.13,  3.50,  0.23,  1.38]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [218.50 , 46.75, 17.13, 5.75,  2.88,  0.00,  4.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [105.08 , 33.82, 11.68, 1.66,  3.95,  0.29,  1.55]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [28.17, 15.67, 6.75,  0.75,  6.83,  0.92,  0.58]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [62.41, 20.91, 7.05,  6.36,  2.50,  0.00,  0.50]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [142.83 , 37.17, 18.33, 7.50,  4.67,  0.00,  1.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [147.78 , 55.00, 18.22, 0.11,  2.11,  0.00,  2.78]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [154.40 , 28.40, 13.90, 8.00,  4.90,  0.00,  3.40]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [67.90, 50.00, 18.20, 0.50,  4.10,  0.00,  1.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [92.40, 24.00, 0.80,  2.00,  2.80,  0.00,  0.60]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [48.50, 26.75, 2.50,  1.25,  0.00,  0.00,  0.75]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [28.70, 9.90 , 3.30,  8.70,  1.80,  0.00,  0.70]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [34.67, 16.67, 2.89,  5.56,  3.33,  1.22,  0.78]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [108.90 , 22.30, 11.60, 0.00,  3.40,  0.00,  0.90]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [53.17, 10.33, 2.67,  0.00,  4.83,  0.33,  0.33]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [23.07, 13.93, 3.86,  1.64,  3.00,  0.64,  0.93]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [49.25, 18.25, 3.75,  3.50,  1.50,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [52.67, 7.00 , 8.33,  1.33,  8.67,  0.00,  0.67]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [76.11, 37.56, 9.56,  11.56, 2.00,  0.00,  0.56]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [87.20, 67.60, 9.20,  0.80,  4.00,  0.00,  2.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [308.86 , 51.00, 33.57, 0.14,  3.86,  0.00,  4.71]
              },
            ],
            "questionName": "How many people are currently employed by your organization in each of the following categories? Please choose the best single category for each employee if more than one could apply.   If the value is zero, please input 0.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 1,
            "labels" : ["Less than $25,000", "$25,000 – 49,999" ,"$50,000 – 74,999" ,"$75,000 – 99,999" ,"$100,000 – 149,999" ,"$150,000 – 199,000", "$200,000 – 299,999", "More than $300,000"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [3.00,  28.35 ,29.65 ,14.10 ,9.92  ,3.44  ,1.50  ,0.75]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [9.88,  52.88 ,68.63 ,40.25 ,32.50 ,14.50 ,3.00  ,1.63]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [3.50,  33.34 ,34.45 ,16.92 ,11.76 ,4.16  ,1.61  ,0.87]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.58,  11.08 ,10.92 ,5.58  ,1.75  ,0.33  ,0.67  ,0.17]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [2.95,  22.45 ,21.82 ,7.91  ,5.95  ,1.45  ,1.23  ,0.68]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [6.17,  28.83 ,44.67 ,24.00 ,17.67 ,3.50  ,2.67  ,1.17]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [6.78,  49.44 ,42.33 ,24.22 ,15.11 ,3.56  ,2.33  ,0.78]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.00,  36.80 ,50.10 ,27.90 ,22.00 ,10.30 ,2.90  ,1.70]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [3.50,  32.40 ,21.60 ,6.90  ,4.90  ,1.30  ,0.80  ,0.40]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [12.80, 43.40 ,34.60 ,9.00  ,7.60  ,1.60  ,1.40  ,0.60]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.25,  24.75 ,16.50 ,5.25  ,3.25  ,0.50  ,0.75  ,0.50]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [1.10,  9.40  ,11.40 ,3.40  ,2.90  ,0.70  ,1.10  ,0.30]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [3.67,  17.00 ,10.67 ,4.67  ,1.44  ,0.44  ,0.56  ,0.33]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [1.10,  20.00 ,37.10 ,22.10 ,14.30 ,3.50  ,2.00  ,0.70]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [10.50, 22.00 ,17.83 ,5.67  ,3.67  ,0.67  ,1.17  ,0.17]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.50,  9.71  ,9.50  ,3.50  ,1.71  ,0.29  ,0.57  ,0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [6.75,  19.75 ,16.75 ,4.00  ,2.75  ,0.50  ,0.75  ,0.25]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00,  23.67 ,15.67 ,6.67  ,2.67  ,1.00  ,1.33  ,0.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [5.22,  36.00 ,21.33 ,8.89  ,5.89  ,1.11  ,1.11  ,1.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00,  36.00 ,29.40 ,17.80 ,8.20  ,2.20  ,2.00  ,1.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.00  ,62.71, 104.29  ,55.57 ,45.29 ,18.71 ,4.29  ,2.57]
              },
            ],
            "questionName": "In the past calendar year (2021), how many full-time staff in your organization received an annual salary/compensation of...",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 2,
            "labels" : ["Less than $25,000", "$25,000 – 49,999" ,"$50,000 – 74,999" ,"$75,000 – 99,999" ,"$100,000 – 149,999" ,"$150,000 – 199,000", "$200,000 – 299,999", "More than $300,000"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [27.54 ,10.46 ,3.13  ,1.06  ,0.60  ,0.31  ,0.06  ,0.04]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [57.00 ,7.00  ,3.00  ,2.13  ,1.50  ,1.38  ,0.13  ,0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [32.71 ,10.50 ,2.55  ,1.18  ,0.66  ,0.39  ,0.08  ,0.05]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [20.75 ,4.08  ,0.58  ,0.08  ,0.00  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [15.68 ,11.00 ,3.64  ,0.50  ,0.23  ,0.00  ,0.05  ,0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [59.33 ,7.50  ,2.33  ,0.67  ,0.00  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [48.67 ,21.22 ,5.67  ,2.56  ,1.33  ,0.44  ,0.11  ,0.11]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [28.00 ,8.80  ,6.20  ,2.20  ,1.70  ,1.10  ,0.10  ,0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [41.00 ,15.50 ,1.20  ,0.00  ,0.00  ,0.00  ,0.10  ,0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [15.80 ,8.80  ,0.60  ,0.00  ,0.00  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [31.25 ,7.75  ,0.25  ,0.25  ,0.00  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [7.90  ,10.30 ,5.30  ,0.60  ,0.40  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [24.11 ,3.22  ,0.00  ,0.33  ,0.00  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [21.10 ,15.50 ,7.20  ,2.50  ,1.20  ,0.40  ,0.10  ,0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [22.83 ,3.17  ,0.00  ,0.00  ,0.00  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [17.21 ,4.64  ,0.07  ,0.07  ,0.00  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [21.75 ,11.50 ,0.75  ,0.00  ,0.00  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [3.33  ,4.67  ,1.00  ,0.00  ,0.00  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [29.56 ,13.33 ,6.00  ,1.00  ,0.44  ,0.00  ,0.00  ,0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [54.40 ,15.20 ,7.40  ,4.40  ,2.60  ,0.80  ,0.20  ,0.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [44.14 ,23.14 ,7.43  ,2.71  ,1.71  ,1.57  ,0.29  ,0.14]
              },
            ],
            "questionName": "In the past calendar year (2021), how many part-time staff, seasonal staff, temporary staff, and paid interns in your organization received an annual salary/compensation of...",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 3,
            "labels" : ["Less than $25,000", "$25,000 – 49,999" ,"$50,000 – 74,999" ,"$75,000 – 99,999" ,"$100,000 – 149,999" ,"$150,000 – 199,000", "$200,000 – 299,999", "More than $300,000", "Uncategorized Promotions"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.58 , 3.94 , 4.77 , 1.90 , 1.19 , 0.63 , 0.65,  0.52,  1.42]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.00 , 6.00 , 8.88 , 2.38 , 2.00 , 0.25 , 0.13,  0.00,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.13 , 4.18 , 5.08 , 1.58 , 0.82 , 0.16 , 0.18,  0.05,  1.26]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00 , 0.92 , 0.75 , 0.42 , 0.08 , 0.00 , 0.00,  0.00,  1.50]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [1.14 , 3.82 , 3.64 , 2.14 , 1.50 , 1.18 , 1.23,  1.09,  1.68]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.33 , 5.33 , 11.17, 3.50 , 1.00 , 0.33 , 0.67,  0.17,  2.83]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.11 , 5.22 , 4.33 , 1.44 , 0.78 , 0.00 , 0.11,  0.00,  1.44]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.40 , 8.80 , 9.00 , 3.00 , 1.50 , 0.30 , 0.40,  0.10,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00 , 0.70 , 3.70 , 1.20 , 0.80 , 0.20 , 0.00,  0.00,  2.40]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00 , 4.20 , 4.40 , 0.40 , 0.20 , 0.00 , 0.00,  0.00,  4.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.25 , 3.25 , 2.25 , 0.50 , 0.25 , 0.00 , 0.00,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [2.30 , 3.00 , 3.60 , 3.10 , 2.60 , 2.40 , 2.40,  2.30,  2.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.00 , 1.67 , 0.56 , 0.44 , 0.00 , 0.00 , 0.00,  0.00,  2.33]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [2.30 , 4.50 , 6.60 , 4.10 , 3.20 , 2.50 , 2.70,  2.40,  0.30]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [3.83 , 7.50 , 6.50 , 4.33 , 4.00 , 3.83 , 3.83,  3.83,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00 , 0.86 , 1.00 , 0.29 , 0.07 , 0.00 , 0.00,  0.00,  1.57]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00 , 0.75 , 1.00 , 0.25 , 0.50 , 0.00 , 0.00,  0.00,  5.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00 , 1.67 , 2.33 , 1.33 , 0.33 , 0.00 , 0.00,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.11 , 5.22 , 3.22 , 1.22 , 0.44 , 0.11 , 0.11,  0.00,  2.89]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.40 , 5.80 , 14.40, 4.40 , 2.80 , 0.40 , 0.60,  0.20,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.29 , 6.86 , 9.14 , 3.29 , 1.57 , 0.57 , 0.57,  0.14,  0.00]
              },
            ],
            "questionName": "In the past calendar year (2021), how many staff received a promotion (with title change and pay increase beyond cost of living) that resulted in a salary within each of the following pay levels?...",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 4,
            "labels" : ["Less than $25,000", "$25,000 – 49,999" ,"$50,000 – 74,999" ,"$75,000 – 99,999" ,"$100,000 – 149,999" ,"$150,000 – 199,000", "$200,000 – 299,999", "More than $300,000", "Uncategorized Hires"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [9.54  ,8.56 , 4.40 , 1.40,  0.79,  0.35,  0.15,  0.06,  2.77]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [13.25 ,10.63, 6.50 , 3.38,  1.50,  0.88,  0.25,  0.38,  10.8]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [11.71 ,9.08 , 4.26 , 1.61,  0.76,  0.42,  0.13,  0.08,  3.50]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [2.92  ,1.75 , 1.08 , 0.25,  0.17,  0.33,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [5.73  ,7.86 , 5.23 , 1.18,  0.73,  0.27,  0.18,  0.00,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [28.17 ,11.67, 5.83 , 2.67,  1.17,  0.67,  0.17,  0.00,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [18.33 ,15.00, 5.78 , 2.22,  0.89,  0.22,  0.11,  0.11,  5.11]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [17.20 ,12.40, 6.70 , 2.90,  2.00,  0.70,  0.50,  0.20,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [10.60 ,12.50, 1.80 , 0.50,  0.10,  0.20,  0.10,  0.10,  8.70]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [10.00 ,9.20 , 6.60 , 0.60,  0.60,  0.00,  0.20,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [10.50 ,8.50 , 2.25 , 1.00,  0.50,  0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [1.30  ,6.60 , 4.90 , 0.60,  0.90,  0.10,  0.20,  0.00,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [4.22  ,3.89 , 1.22 , 0.44,  0.00,  0.11,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [5.00  ,4.70 , 7.30 , 2.20,  1.20,  0.70,  0.00,  0.00,  4.60]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [9.33  ,2.17 , 2.17 , 0.17,  0.33,  0.00,  0.17,  0.00,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [3.07  ,3.50 , 1.14 , 0.21,  0.14,  0.07,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [14.25 ,13.50, 6.50 , 0.25,  0.25,  0.25,  0.50,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.33  ,9.00 , 3.33 , 2.00,  1.00,  0.33,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [9.78  ,13.67, 5.56 , 1.44,  0.78,  0.44,  0.22,  0.00,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [9.20  ,3.00 , 1.40 , 1.20,  0.80,  0.00,  0.20,  0.00,  26.6]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [23.43 ,18.57, 12.71, 5.29,  2.71,  1.43,  0.14,  0.43,  0.00]
              },
            ],
            "questionName": "Over the past year (2021), how many staff members were hired by your organization at pay level:",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 5,
            "labels" : ["Number of Hires"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [21.75]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [47.88]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [24.11]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [4.50]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [18.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [33.33]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [35.89]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [31.60]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [17.50]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [27.40]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [13.75]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [12.80]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [10.89]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [26.30]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [15.67]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [7.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [20.75]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [19.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [22.78]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [22.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [56.57]
              },
            ],
            "questionName": "In the past TWO calendar years (2020 and 2021), how many full-time regular employees have been hired by your organization. If zero, please write 0.",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 6,
            "labels" : ["Number of Employees"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [15.42]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [33.63]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [17.29]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [3.83]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [13.05]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [23.17]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [23.22]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [23.60]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [11.70]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [15.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [8.75]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [8.30]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [7.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [21.30]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [7.67]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [4.64]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [13.50]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [12.67]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [14.67]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [18.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [45.00]
              },
            ],
            "questionName": "How many of those full-time regular employees you hired in the past TWO calendar years (mentioned in the previous question) still work for your organization?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 7,
            "labels" : ["Yes, for all positions", "Yes, but only for specific positions", "No"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.46 , 0.04,  0.44]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.63 , 0.00,  0.38]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.42 , 0.05,  0.47]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.25 , 0.00,  0.75]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.45 , 0.09,  0.32]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.67 , 0.00,  0.33]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.56 , 0.00,  0.44]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.40 , 0.10,  0.50]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.30 , 0.00,  0.50]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.60 , 0.20,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.25 , 0.00,  0.75]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.60 , 0.00,  0.30]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.33 , 0.00,  0.67]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.80 , 0.00,  0.20]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.67 , 0.00,  0.33]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.29 , 0.00,  0.64]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.75 , 0.00,  0.25]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67 , 0.00,  0.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.33 , 0.00,  0.56]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.80 , 0.20,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.29 , 0.14,  0.43]
              },
            ],
            "questionName": "Are new employees required to sign binding arbitration or confidentiality agreements during their onboarding process (e.g., in an employment letter, employee handbook)?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 8,
            "labels" : ["Less than $25,000", "$25,000 – 49,999" ,"$50,000 – 74,999" ,"$75,000 – 99,999" ,"$100,000 – 149,999" ,"$150,000 – 199,000", "$200,000 – 299,999", "More than $300,000", "Uncategorized Departures"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [5.81  ,7.40  ,3.23  ,1.23  ,0.92  ,0.42  ,0.10  ,0.06  ,4.79 ]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [10.00 ,19.38 ,7.25  ,2.63  ,2.25  ,0.50  ,0.25  ,0.13  ,9.50 ]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [7.05  ,8.05  ,3.71  ,1.34  ,1.08  ,0.53  ,0.13  ,0.05  ,5.26 ]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [2.17  ,1.58  ,1.17  ,0.83  ,0.17  ,0.08  ,0.00  ,0.00  ,0.00 ]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [3.32  ,5.68  ,2.73  ,1.00  ,0.77  ,0.45  ,0.14  ,0.09  ,3.55 ]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [15.17 ,5.83  ,2.50  ,0.50  ,0.50  ,0.67  ,0.00  ,0.00  ,0.00 ]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [10.67 ,9.44  ,4.56  ,1.22  ,1.00  ,0.22  ,0.22  ,0.11  ,8.44 ]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [8.20  ,17.40 ,5.80  ,2.40  ,2.00  ,1.00  ,0.10  ,0.10  ,4.80 ]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [5.80  ,7.00  ,1.70  ,0.20  ,0.30  ,0.10  ,0.10  ,0.10  ,13.70]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [11.60 ,3.80  ,4.20  ,1.40  ,1.40  ,0.00  ,0.20  ,0.00  ,6.00 ]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [7.75  ,7.00  ,2.75  ,1.25  ,0.50  ,0.00  ,0.00  ,0.00  ,0.00 ]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [1.10  ,4.90  ,1.40  ,0.80  ,0.30  ,0.00  ,0.00  ,0.10  ,3.00 ]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [3.11  ,2.22  ,1.11  ,0.11  ,0.22  ,0.00  ,0.00  ,0.00  ,0.00 ]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [2.20  ,4.40  ,3.80  ,2.00  ,1.00  ,0.90  ,0.20  ,0.10  ,1.50 ]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [8.33  ,3.83  ,3.33  ,0.83  ,0.67  ,0.00  ,0.17  ,0.00  ,0.00 ]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [2.07  ,2.71  ,0.93  ,0.36  ,0.36  ,0.00  ,0.00  ,0.00  ,0.00 ]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [8.25  ,8.00  ,2.50  ,0.25  ,0.50  ,0.75  ,0.25  ,0.25  ,7.50 ]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [2.00  ,9.33  ,4.67  ,1.33  ,0.33  ,0.00  ,0.00  ,0.00  ,0.00 ]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [5.33  ,6.56  ,3.00  ,1.67  ,0.11  ,0.22  ,0.00  ,0.11  ,6.78 ]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00  ,2.00  ,0.80  ,0.60  ,0.60  ,0.20  ,0.00  ,0.00  ,27.80]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [16.14 ,23.57 ,9.57  ,3.71  ,4.00  ,2.00  ,0.43  ,0.14  ,0.00 ]
              },
            ],
            "questionName": "Over the past calendar year (2021), how many staff members departed your organization (voluntary or involuntary) at pay level:",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 9,
            "labels" : ["Number of Staff"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.40]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.88]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.50]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.50]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [1.33]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.90]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.20]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.78]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.50]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.78]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.80]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.86]
              },
            ],
            "questionName": "How many of those staff who departed your organization (voluntary or involuntary) over the past calendar year signed NDAs (Non-Disclosure Agreements)?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 10,
            "labels" : ["Artists in the collection","Board and/or trustee members","Staff members","Volunteers","None of the above", "I don’t know"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.38,  0.67,  0.79,  0.19,  0.08,  0.04]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.63,  0.75,  0.75,  0.38,  0.25,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.45,  0.66,  0.82,  0.21,  0.05,  0.05]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.33,  0.50,  0.83,  0.25,  0.00,  0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.27,  0.73,  0.73,  0.09,  0.09,  0.05]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.33,  0.67,  0.67,  0.00,  0.33,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.56,  0.78,  1.00,  0.33,  0.00,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.40,  0.80,  0.90,  0.30,  0.00,  0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.40,  0.60,  0.70,  0.20,  0.10,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.20,  0.80,  0.80,  0.20,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.50,  0.50,  0.50,  0.25,  0.25,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.10,  0.70,  0.70,  0.10,  0.20,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.56,  0.56,  0.78,  0.22,  0.11,  0.11]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.20,  0.70,  0.90,  0.00,  0.10,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.33,  0.67,  0.83,  0.33,  0.17,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.43,  0.50,  0.71,  0.14,  0.07,  0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.75,  0.75,  0.00,  0.00,  0.25]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.33,  1.00,  1.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.44,  0.67,  0.78,  0.33,  0.11,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.40,  0.60,  0.80,  0.00,  0.20,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.43,  0.86,  0.86,  0.29,  0.00,  0.00]
              },
            ],
            "questionName": "Has your organization measured the composition of any of the following groups with respect to gender, race, and ethnicity within the last 3 years?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 11,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.29,  0.04,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.38,  0.00,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.24,  0.03,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.08,  0.00,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.41,  0.09,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.33,  0.00,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.11,  0.00,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.30,  0.10,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.20,  0.00,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.20,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.50,  0.10,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.22,  0.00,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.60,  0.10,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.50,  0.00,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.14,  0.07,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.50,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.33,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.33,  0.00,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.40,  0.00,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.14,  0.14,  0.00]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Health insurance (covered 100% by the employer)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 12,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.81,  0.38,  0.10]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.63,  0.25,  0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.84,  0.39,  0.13]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.92,  0.83,  0.33]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.77,  0.23,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.83,  0.50,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [1.00,  0.11,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [1.00,  0.70,  0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.70,  0.20,  0.10]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [1.00,  0.25,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.70,  0.30,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.89,  0.44,  0.22]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.60,  0.40,  0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.50,  0.50,  0.17]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.93,  0.43,  0.14]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.75,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67,  0.33,  0.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.89,  0.33,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.80,  0.20,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.86,  0.57,  0.14]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Health insurance (covered partially by the employer)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 13,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.79 , 0.27 , 0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.63 , 0.13 , 0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.82 , 0.29 , 0.03]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.92 , 0.58 , 0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.77 , 0.18 , 0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.67 , 0.17 , 0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [1.00 , 0.22 , 0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.80 , 0.30 , 0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.70 , 0.30 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80 , 0.00 , 0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [1.00 , 0.50 , 0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.70 , 0.20 , 0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.89 , 0.11 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.70 , 0.40 , 0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.67 , 0.17 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.86 , 0.36 , 0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.75 , 0.25 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67 , 0.33 , 0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.89 , 0.33 , 0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.80 , 0.00 , 0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.71 , 0.29 , 0.00]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Disability insurance (long and short-term)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 15,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.79 , 0.31 , 0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.88 , 0.25 , 0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.84 , 0.34 , 0.03]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.83 , 0.67 , 0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.68 , 0.18 , 0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.67 , 0.17 , 0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [1.00 , 0.22 , 0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.70 , 0.40 , 0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.70 , 0.30 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80 , 0.00 , 0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [1.00 , 0.25 , 0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.60 , 0.20 , 0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.89 , 0.33 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.80 , 0.40 , 0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.50 , 0.17 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.86 , 0.50 , 0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.50 , 0.00 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.00 , 0.33 , 0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.89 , 0.33 , 0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.80 , 0.00 , 0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.86 , 0.43 , 0.00]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Life Insurance",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 16,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.94 , 0.56,  0.06]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [1.00 , 0.63,  0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.95 , 0.61,  0.08]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [1.00 , 0.83,  0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.86 , 0.45,  0.05]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [1.00 , 0.33,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [1.00 , 0.56,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [1.00 , 0.70,  0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.80 , 0.60,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80 , 0.60,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [1.00 , 0.50,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.90 , 0.40,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [1.00 , 0.33,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [1.00 , 0.60,  0.20]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [1.00 , 0.50,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.93 , 0.57,  0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [1.00 , 1.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.00 , 0.33,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.89 , 0.33,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [1.00 , 0.80,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.86 , 0.57,  0.29]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Paid time-off",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 17,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.85 , 0.67 , 0.25]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.88 , 0.75 , 0.25]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.84 , 0.71 , 0.29]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.92 , 0.83 , 0.25]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.77 , 0.50 , 0.23]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.83 , 0.50 , 0.17]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [1.00 , 0.78 , 0.22]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [1.00 , 1.00 , 0.40]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.60 , 0.50 , 0.10]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80 , 0.60 , 0.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [1.00 , 0.75 , 0.50]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.90 , 0.50 , 0.10]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.78 , 0.33 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [1.00 , 0.80 , 0.40]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [1.00 , 0.50 , 0.17]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.86 , 0.57 , 0.14]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.75 , 0.75 , 0.25]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.00 , 1.00 , 0.67]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.78 , 0.56 , 0.11]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.80 , 1.00 , 0.40]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.86 , 0.71 , 0.43]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Paid sick leave",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 18,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.63 , 0.35 , 0.06]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.50 , 0.25 , 0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.68 , 0.39 , 0.08]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.83 , 0.58 , 0.17]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.55 , 0.27 , 0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.67 , 0.33 , 0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.56 , 0.22 , 0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.70 , 0.60 , 0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.20 , 0.10 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.40 , 0.00 , 0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.75 , 0.50 , 0.25]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.40 , 0.20 , 0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.89 , 0.33 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.80 , 0.50 , 0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.50 , 0.33 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.64 , 0.29 , 0.14]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.50 , 0.50 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.00 , 0.33 , 0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.67 , 0.33 , 0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.40 , 0.20 , 0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.71 , 0.57 , 0.14]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Paid family leave (equal to the statutory requirement)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 19,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.23 , 0.08 , 0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.13 , 0.00 , 0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.21 , 0.08 , 0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.33 , 0.17 , 0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.23 , 0.09 , 0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.17 , 0.00 , 0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.11 , 0.00 , 0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.10 , 0.00 , 0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.30 , 0.10 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.20 , 0.20 , 0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.30 , 0.10 , 0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.33 , 0.11 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.30 , 0.10 , 0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.33 , 0.00 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.29 , 0.07 , 0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.50 , 0.25 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.33 , 0.33 , 0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.20 , 0.00 , 0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.14 , 0.14 , 0.00]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Paid family leave (beyond the statutory requirement but not including paternal or maternal leave)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 20,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.56,  0.21,  0.04]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.50,  0.25,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.55,  0.21,  0.05]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.75,  0.33,  0.17]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.64,  0.14,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.33,  0.17,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.22,  0.00,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.60,  0.30,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.50,  0.20,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.60,  0.20,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.75,  0.25,  0.25]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.60,  0.20,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.78,  0.11,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.30,  0.20,  0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.50,  0.00,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.79,  0.29,  0.14]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.75,  0.25,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67,  0.33,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.22,  0.00,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.40,  0.20,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.57,  0.43,  0.00]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Paid paternal and/or maternal leave beyond any statutory requirement",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.73 , 0.60 , 0.42]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.75 , 0.75 , 0.75]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.74 , 0.66 , 0.47]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.75 , 0.67 , 0.33]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.73 , 0.50 , 0.36]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.50 , 0.50 , 0.50]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.89 , 0.89 , 0.56]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.80 , 0.80 , 0.40]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.60 , 0.40 , 0.30]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80 , 0.40 , 0.40]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.75 , 0.75 , 0.75]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.70 , 0.40 , 0.20]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.56 , 0.44 , 0.22]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.90 , 0.80 , 0.60]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.67 , 0.50 , 0.17]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.79 , 0.50 , 0.29]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.75 , 0.75 , 0.50]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.00 , 1.00 , 1.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.56 , 0.56 , 0.33]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.60 , 0.60 , 0.40]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.86 , 0.71 , 0.71]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Workplace safety protections",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.75 , 0.42 , 0.08]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.88 , 0.38 , 0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.84 , 0.45 , 0.08]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.92 , 0.58 , 0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.64 , 0.41 , 0.09]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.67 , 0.33 , 0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.89 , 0.44 , 0.11]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.70 , 0.40 , 0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.60 , 0.20 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80 , 0.40 , 0.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [1.00 , 0.75 , 0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.40 , 0.30 , 0.10]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.89 , 0.44 , 0.11]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.70 , 0.50 , 0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.67 , 0.17 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.71 , 0.50 , 0.14]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [1.00 , 0.50 , 0.25]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.00 , 0.67 , 0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.56 , 0.33 , 0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.80 , 0.40 , 0.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.86 , 0.43 , 0.00]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? 401k/403b/other retirement funds (with employer contribution)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.40,  0.25,  0.04]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.38,  0.25,  0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.32,  0.21,  0.05]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.42,  0.25,  0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.41,  0.32,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.83,  0.33,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.33,  0.22,  0.11]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.60,  0.50,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.30,  0.30,  0.20]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.20,  0.20,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.70,  0.40,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.56,  0.22,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.40,  0.10,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.67,  0.17,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.36,  0.21,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.25,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00,  0.00,  0.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.78,  0.56,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.20,  0.20,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.29,  0.14,  0.14]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? 401k/403b/other retirement funds (without employer contribution)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.35,  0.17,  0.08]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.25,  0.13,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.37,  0.18,  0.11]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.50,  0.25,  0.17]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.41,  0.18,  0.09]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.17,  0.17,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.11,  0.11,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.30,  0.20,  0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.30,  0.20,  0.10]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.40,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.25,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.30,  0.10,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.56,  0.22,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.30,  0.20,  0.20]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.33,  0.17,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.43,  0.21,  0.14]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.50,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.22,  0.22,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.20,  0.20,  0.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.29,  0.14,  0.14]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Mental health coverage (beyond health insurance)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.77 , 0.60,  0.29]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.88 , 0.75,  0.38]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.84 , 0.68,  0.37]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.92 , 0.75,  0.25]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.64 , 0.41,  0.23]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.67 , 0.67,  0.33]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.89 , 0.89,  0.44]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [1.00 , 0.90,  0.40]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.70 , 0.60,  0.30]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80 , 0.20,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.75 , 0.75,  0.25]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.50 , 0.30,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.56 , 0.44,  0.11]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.80 , 0.60,  0.50]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.67 , 0.50,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.57 , 0.43,  0.21]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [1.00 , 0.25,  0.25]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.00 , 0.67,  0.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.78 , 0.78,  0.22]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [1.00 , 1.00,  0.40]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.86 , 0.71,  0.71]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Employee Assistance Program (other than mental health coverage)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.79 , 0.27 , 0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.88 , 0.13 , 0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.76 , 0.26 , 0.03]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.92 , 0.50 , 0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.77 , 0.23 , 0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.83 , 0.33 , 0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.78 , 0.22 , 0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.80 , 0.50 , 0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.70 , 0.30 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80 , 0.00 , 0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.50 , 0.25 , 0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.90 , 0.30 , 0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.78 , 0.00 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [1.00 , 0.40 , 0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [1.00 , 0.17 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.79 , 0.29 , 0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.75 , 0.00 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67 , 0.33 , 0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.78 , 0.44 , 0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [1.00 , 0.20 , 0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.57 , 0.29 , 0.00]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Dental insurance (fully covered or shared by employer)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.79,  0.27,  0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.63,  0.13,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.76,  0.26,  0.03]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.92,  0.50,  0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.82,  0.23,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [1.00,  0.33,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.67,  0.22,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.90,  0.50,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.60,  0.30,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.75,  0.25,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.90,  0.30,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.78,  0.00,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.90,  0.40,  0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [1.00,  0.17,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.79,  0.29,  0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.75,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67,  0.33,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.78,  0.44,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [1.00,  0.20,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.57,  0.29,  0.00]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Vision insurance (fully covered or shared by employer)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.88,  0.42,  0.13]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [1.00,  0.63,  0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.89,  0.45,  0.13]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.83,  0.50,  0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.82,  0.36,  0.18]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [1.00,  0.67,  0.33]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [1.00,  0.44,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [1.00,  0.60,  0.20]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.80,  0.40,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80,  0.40,  0.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [1.00,  0.25,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.80,  0.30,  0.10]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.89,  0.22,  0.11]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.80,  0.50,  0.20]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.67,  0.33,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.93,  0.36,  0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [1.00,  0.75,  0.25]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.00,  0.33,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.78,  0.22,  0.22]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [1.00,  0.40,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.86,  0.71,  0.29]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Bereavement leave",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.81 , 0.42 , 0.13]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.88 , 0.50 , 0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.82 , 0.45 , 0.13]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.92 , 0.50 , 0.25]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.73 , 0.36 , 0.14]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.83 , 0.50 , 0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [1.00 , 0.56 , 0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.80 , 0.40 , 0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.70 , 0.40 , 0.10]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.60 , 0.40 , 0.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [1.00 , 0.50 , 0.25]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.80 , 0.30 , 0.10]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.89 , 0.33 , 0.11]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.90 , 0.50 , 0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.83 , 0.17 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.86 , 0.50 , 0.21]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.75 , 0.50 , 0.25]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [1.00 , 0.67 , 0.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.89 , 0.33 , 0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [1.00 , 0.60 , 0.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.43 , 0.29 , 0.00]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Funding for professional development",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.40,  0.31,  0.13]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.50,  0.38,  0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.39,  0.32,  0.16]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.25,  0.25,  0.17]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.41,  0.32,  0.14]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.50,  0.50,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.44,  0.33,  0.11]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.90,  0.70,  0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.10,  0.10,  0.10]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.20,  0.20,  0.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.25,  0.25,  0.25]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.40,  0.30,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.11,  0.11,  0.11]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.60,  0.40,  0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.67,  0.50,  0.33]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.14,  0.14,  0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.25,  0.25,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67,  0.33,  0.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.22,  0.22,  0.11]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.80,  0.40,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.57,  0.57,  0.14]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Commuter benefits",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Full-time", "Part-time", "Temporary/Seasonal"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.17 , 0.06 , 0.02]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.25 , 0.00 , 0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.18 , 0.05 , 0.03]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.17 , 0.08 , 0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.23 , 0.09 , 0.05]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.50 , 0.17 , 0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.20 , 0.20 , 0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.10 , 0.00 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.20 , 0.00 , 0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.10 , 0.10 , 0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.33 , 0.00 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.10 , 0.10 , 0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.33 , 0.17 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.22 , 0.00 , 0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.20 , 0.00 , 0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.43 , 0.29 , 0.14]
              },
            ],
            "questionName": "Which of the following benefits does your organization provide for the following types of employees? Pet insurance (fully covered or shared by employer)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["The museum shares the specific salaries of all employees publicly","A union handbook lists salary levels for each job at the museum","The museum shares salary ranges for all positions or levels with employees","The museum posts salary ranges for each open position ","The museum actively discourages employees from discussing their salaries","None of the above"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.15,  0.17,  0.31,  0.71,  0.04,  0.15]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.13,  0.25,  0.25,  0.75,  0.00,  0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.18,  0.18,  0.34,  0.66,  0.05,  0.18]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.50,  0.25,  0.75,  0.75,  0.17,  0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.00,  0.14,  0.14,  0.68,  0.00,  0.14]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00,  0.17,  0.00,  0.50,  0.00,  0.33]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.11,  0.11,  0.11,  0.67,  0.00,  0.22]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.00,  0.10,  0.20,  0.80,  0.00,  0.20]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.40,  0.20,  0.30,  0.60,  0.00,  0.20]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00,  0.00,  0.20,  0.80,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00,  0.25,  0.25,  1.00,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00,  0.10,  0.20,  0.90,  0.00,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.11,  0.00,  0.44,  0.56,  0.22,  0.22]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.20,  0.40,  0.40,  0.70,  0.00,  0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.17,  0.00,  0.33,  0.83,  0.17,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.21,  0.14,  0.50,  0.79,  0.07,  0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00,  0.50,  0.00,  0.50]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.33,  0.33,  1.00,  1.00,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.11,  0.22,  0.11,  0.56,  0.00,  0.33]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00,  0.20,  0.20,  0.80,  0.00,  0.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.14,  0.29,  0.14,  0.57,  0.00,  0.00]
              },
            ],
            "questionName": "Which of the following statements best reflect the salary sharing practices of your museum?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["An HR complaint form (i.e., in-person or online)","An HR staff member who is available to employees","A union-provided grievance process for reporting","An anonymous reporting mechanism","An employee complaint hotline","A neutral employee or manager who can communicate the issues to HR","A third-party reporting process (e.g., use of an ombudsman)","Another reporting mechanism","My organization doesn’t have any reporting mechanisms"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.60 , 0.88 , 0.33 , 0.58,  0.31,  0.44,  0.33,  0.15,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.63 , 1.00 , 0.38 , 0.75,  0.63,  0.50,  0.50,  0.25,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.61 , 0.89 , 0.39 , 0.61,  0.37,  0.47,  0.37,  0.13,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.75 , 1.00 , 0.58 , 0.92,  0.50,  0.67,  0.83,  0.17,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.55 , 0.73 , 0.23 , 0.41,  0.18,  0.27,  0.14,  0.14,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.67 , 1.00 , 0.33 , 0.50,  0.17,  0.17,  0.33,  0.17,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.56 , 1.00 , 0.11 , 0.56,  0.33,  0.78,  0.22,  0.22,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.90 , 0.90 , 0.40 , 0.80,  0.30,  0.30,  0.20,  0.10,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.40 , 0.70 , 0.40 , 0.70,  0.30,  0.40,  0.30,  0.00,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.80 , 0.80 , 0.00 , 0.40,  0.20,  0.60,  0.20,  0.20,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.25 , 1.00 , 0.25 , 0.25,  0.00,  0.25,  0.25,  0.50,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.60 , 0.80 , 0.10 , 0.50,  0.10,  0.30,  0.20,  0.20,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.56 , 1.00 , 0.33 , 0.56,  0.44,  0.56,  0.44,  0.11,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.60 , 0.90 , 0.40 , 0.50,  0.40,  0.50,  0.50,  0.20,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.67 , 0.83 , 0.17 , 0.67,  0.33,  0.33,  0.83,  0.33,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.50 , 0.93 , 0.29 , 0.64,  0.21,  0.71,  0.36,  0.21,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.50 , 0.50 , 0.25 , 0.25,  0.00,  0.25,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.67 , 1.00 , 0.67 , 0.33,  0.33,  0.33,  0.67,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.78 , 0.89 , 0.22 , 0.56,  0.33,  0.33,  0.22,  0.22,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.60 , 1.00 , 0.40 , 1.00,  0.60,  0.40,  0.40,  0.00,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.57 , 0.86 , 0.57 , 0.43,  0.43,  0.29,  0.00,  0.00,  0.00]
              },
            ],
            "questionName": "What kind of reporting mechanisms for workplace disputes, harassment, or discrimination are currently in place at your organization?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Formal Complaints: Workplace culture (e.g., being welcomed/included)", "Informal Complaints: Workplace culture (e.g., being welcomed/included)","Formal Complaints: Compensation/Salary", "Informal Complaints: Compensation/Salary","Formal Complaints: Title/Promotion/Advancement", "Informal Complaints: Title/Promotion/Advancement","Formal Complaints: Racism, race-based discrimination, microaggressions", "Informal Complaints: Racism, race-based discrimination, microaggressions","Formal Complaints: Gender, sexual orientation, gender expression, gender-based discrimination", "Informal Complaints: Gender, sexual orientation, gender expression, gender-based discrimination"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [1.17 , 2.88 , 1.08 , 2.56 , 0.33 , 1.75 , 0.94 , 2.04 , 0.48 , 0.75]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [2.88 , 12.13, 3.88 , 4.63 , 1.13 , 2.75 , 4.38 , 9.75 , 1.38 , 2.38]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [1.39 , 3.53 , 1.37 , 3.08 , 0.42 , 2.08 , 1.16 , 2.42 , 0.53 , 0.92]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.17 , 1.00 , 0.08 , 2.17 , 0.17 , 1.08 , 0.17 , 0.33 , 0.00 , 0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [2.09 , 2.82 , 2.05 , 1.91 , 0.45 , 2.09 , 1.32 , 3.14 , 0.68 , 1.14]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [2.50 , 9.00 , 5.83 , 5.83 , 1.17 , 2.67 , 4.50 , 10.33, 0.83 , 1.67]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.22 , 2.56 , 0.00 , 3.33 , 0.22 , 1.89 , 0.67 , 0.89 , 0.22 , 0.11]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [1.40 , 4.00 , 0.70 , 3.60 , 0.30 , 1.00 , 0.70 , 0.80 , 0.90 , 0.90]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.30 , 3.00 , 0.10 , 2.30 , 0.00 , 1.10 , 0.30 , 1.60 , 0.00 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.40 , 0.00 , 0.00 , 1.00 , 0.00 , 0.80 , 0.20 , 1.00 , 0.00 , 0.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00 , 1.75 , 0.00 , 2.75 , 0.00 , 0.75 , 0.50 , 0.25 , 1.25 , 2.75]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.30 , 0.40 , 0.00 , 0.60 , 0.00 , 0.50 , 0.10 , 0.60 , 0.30 , 0.10]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [2.00 , 5.78 , 3.44 , 3.33 , 0.67 , 1.56 , 2.89 , 6.89 , 0.56 , 1.11]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [1.90 , 0.90 , 1.30 , 1.80 , 0.70 , 4.20 , 0.60 , 0.60 , 0.40 , 0.50]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.33 , 0.17 , 0.00 , 0.83 , 0.00 , 0.83 , 0.00 , 0.00 , 0.00 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.29 , 0.57 , 0.14 , 1.00 , 0.21 , 0.57 , 0.07 , 0.50 , 0.07 , 0.14]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [2.00 , 0.00 , 0.00 , 1.25 , 0.00 , 0.25 , 0.25 , 0.50 , 0.00 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00 , 0.67 , 0.00 , 4.67 , 0.00 , 1.33 , 0.67 , 0.67 , 2.00 , 3.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [1.89 , 8.00 , 3.33 , 5.00 , 0.56 , 2.00 , 2.89 , 6.67 , 1.00 , 1.11]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.40 , 3.60 , 0.60 , 4.40 , 0.20 , 0.20 , 1.20 , 3.20 , 0.20 , 0.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [3.29 , 5.29 , 2.43 , 2.57 , 1.00 , 6.71 , 1.29 , 1.57 , 0.86 , 1.86]
              },
            ],
            "questionName": "In the past calendar year (2021), how many staff (please consider ALL staff members, including Full-Time and Part-Time) have formally and/or informally expressed concern or dissatisfaction related to the following topics:",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Complaints resulted in legal action","Complaints resulted in internal investigation ","Complaints resulted in external investigation ","Complaints resulted in disciplinary action"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.19 , 1.50,  0.04,  0.40]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.00 , 5.50,  0.25,  1.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.24 , 1.71,  0.05,  0.50]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.08 , 0.50,  0.00,  0.08]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.36 , 2.00,  0.09,  0.55]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00 , 4.50,  0.33,  0.83]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00 , 1.78,  0.00,  0.56]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.80 , 1.70,  0.00,  0.90]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00 , 1.70,  0.00,  0.30]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00 , 0.40,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00 , 0.50,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00 , 0.70,  0.00,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.11 , 3.22,  0.22,  0.44]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.00 , 0.50,  0.00,  0.30]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.17 , 0.33,  0.00,  0.17]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00 , 0.71,  0.00,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [2.00 , 2.00,  0.00,  2.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00 , 0.67,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00 , 3.11,  0.22,  0.33]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00 , 1.20,  0.00,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.00 , 2.29,  0.00,  1.00]
              },
            ],
            "questionName": "In the previous question, you reported how many staff members made formal and/or informal complaints about various workplace issues in the past calendar year (2021). How many of these complaints resulted in the following outcome? Workplace culture (e.g., being welcomed/included)",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Complaints resulted in legal action","Complaints resulted in internal investigation ","Complaints resulted in external investigation ","Complaints resulted in disciplinary action"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.00,  1.27,  0.00,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.00,  4.13,  0.00,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.00,  1.61,  0.00,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00,  1.00,  0.00,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.00,  1.68,  0.00,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00,  5.00,  0.00,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00,  1.33,  0.00,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.00,  1.00,  0.00,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00,  1.10,  0.00,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.00,  4.11,  0.00,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.00,  0.30,  0.00,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.67,  0.00,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00,  0.21,  0.00,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00,  2.67,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00,  3.33,  0.00,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00,  2.40,  0.00,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.00,  0.57,  0.00,  0.00]
              },
            ],
            "questionName": "In the previous question, you reported how many staff members made formal and/or informal complaints about various workplace issues in the past calendar year (2021). How many of these complaints resulted in the following outcome? Compensation/Salary",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Complaints resulted in legal action","Complaints resulted in internal investigation ","Complaints resulted in external investigation ","Complaints resulted in disciplinary action"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.02,  0.60,  0.00,  0.00]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.13,  1.88,  0.00,  0.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.03,  0.68,  0.00,  0.00]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00,  0.58,  0.00,  0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.00,  0.68,  0.00,  0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00,  1.67,  0.00,  0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00,  0.56,  0.00,  0.00]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.10,  0.30,  0.00,  0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00,  0.60,  0.00,  0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00,  0.60,  0.00,  0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00,  0.30,  0.00,  0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.00,  1.89,  0.00,  0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.67,  0.00,  0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00,  0.43,  0.00,  0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.00,  0.00,  0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00,  1.00,  0.00,  0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00,  1.11,  0.00,  0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00,  0.20,  0.00,  0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.14,  0.71,  0.00,  0.00]
              },
            ],
            "questionName": "In the previous question, you reported how many staff members made formal and/or informal complaints about various workplace issues in the past calendar year (2021). How many of these complaints resulted in the following outcome? Title/Promotion/Advancement",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Complaints resulted in legal action","Complaints resulted in internal investigation ","Complaints resulted in external investigation ","Complaints resulted in disciplinary action"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.15,  2.10  ,0.10 , 0.08]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.63,  10.25 ,0.00 , 0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.18,  2.58  ,0.11 , 0.11]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00,  0.17  ,0.00 , 0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.09,  3.45  ,0.09 , 0.09]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00,  12.17 ,0.00 , 0.17]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00,  1.44  ,0.33 , 0.22]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.60,  0.90  ,0.00 , 0.10]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00,  0.80  ,0.00 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00,  0.40  ,0.20 , 0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.25,  0.50  ,0.25 , 0.50]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00,  0.30  ,0.10 , 0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.00,  7.89  ,0.00 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.00,  0.90  ,0.30 , 0.10]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00,  0.00  ,0.00 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00,  0.29  ,0.00 , 0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00,  0.00  ,0.25 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.33,  1.00  ,0.33 , 0.33]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00,  7.89  ,0.00 , 0.11]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.20,  2.20  ,0.60 , 0.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.71,  1.71  ,0.00 , 0.14]
              },
            ],
            "questionName": "In the previous question, you reported how many staff members made formal and/or informal complaints about various workplace issues in the past calendar year (2021). How many of these complaints resulted in the following outcome? Racism, race-based discrimination, microaggressions",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Complaints resulted in legal action","Complaints resulted in internal investigation ","Complaints resulted in external investigation ","Complaints resulted in disciplinary action"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.08 , 0.71 , 0.02 , 0.17]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.38 , 2.75 , 0.00 , 0.13]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.11 , 0.82 , 0.03 , 0.16]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00 , 0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.05 , 1.09 , 0.05 , 0.27]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00 , 2.67 , 0.00 , 0.17]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.00 , 0.44 , 0.00 , 0.22]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.40 , 0.90 , 0.00 , 0.30]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00 , 0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00 , 0.00]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00 , 1.25 , 0.25 , 0.75]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00 , 0.30 , 0.00 , 0.20]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.00 , 1.67 , 0.00 , 0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.00 , 0.50 , 0.00 , 0.20]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00 , 0.00]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00 , 0.07 , 0.00 , 0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00 , 0.00 , 0.00 , 0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00 , 2.00 , 0.33 , 1.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00 , 2.11 , 0.00 , 0.22]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.20 , 0.40 , 0.00 , 0.20]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.43 , 0.86 , 0.00 , 0.14]
              },
            ],
            "questionName": "In the previous question, you reported how many staff members made formal and/or informal complaints about various workplace issues in the past calendar year (2021). How many of these complaints resulted in the following outcome? Gender, sexual orientation, gender expression, gender-based discrimination",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Number of Claims"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.19]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [1.00]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.24]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.22]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.70]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.11]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.17]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.07]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [1.00]
              },
            ],
            "questionName": "How many claims were filed with the EEOC (Equal Employment Opportunity Commission) last calendar year (2021)?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
          {
            "id": 21,
            "labels" : ["Number of Claims"],
            "dataSet": [
              {
                "name" : "All Museums",
                "visible" : true,
                "data" : [0.08]
              },
              {
                "name" : "City, State, or County Affiliated",
                "visible" : false,
                "data" : [0.50]
              },
              {
                "name" : "Collecting",
                "visible" : false,
                "data" : [0.11]
              },
              {
                "name" : "College or university-affiliated",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Contemporary and/or modern-specific",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Culturally-specific",
                "visible" : false,
                "data" : [0.50]
              },
              {
                "name" : "Encyclopedic",
                "visible" : false,
                "data" : [0.44]
              },
              {
                "name" : "Mid Atlantic",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Midwest",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Mountain Plains",
                "visible" : false,
                "data" : [0.20]
              },
              {
                "name" : "New England",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Non-Collecting",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Southeast",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "Western",
                "visible" : false,
                "data" : [0.30]
              },
              {
                "name" : "size1",
                "visible" : false,
                "data" : [0.17]
              },
              {
                "name" : "size2",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size3",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size4",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size5",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size6",
                "visible" : false,
                "data" : [0.00]
              },
              {
                "name" : "size7",
                "visible" : false,
                "data" : [0.43]
              },
            ],
            "questionName": "How many claims were filed with your city or state municipality in the last calendar year (2021)?",
            "graphType": "column",
            "questionType": "radiogroup"
          },
        ]
      },
    ]